<script setup lang="ts">
import type { ThemeSwitcherTheme } from '@layouts/types'

const themes: ThemeSwitcherTheme[] = [
  {
    name: 'system',
    icon: 'tabler-device-laptop',
  },
  {
    name: 'light',
    icon: 'tabler-sun-high',
  },
  {
    name: 'dark',
    icon: 'tabler-moon',
  },
  // name: 'システム',
  //   icon: 'tabler-device-laptop',
  // },
  // {
  //   name: '光',
  //   icon: 'tabler-sun-high',
  // },
  // {
  //   name: '闇',
  //   icon: 'tabler-moon',
  // },
]
</script>

<template>
  <ThemeSwitcher :themes="themes" />
</template>
